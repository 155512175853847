/* eslint-disable react/jsx-one-expression-per-line */
import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import useBulkActionsComponent from './hooks/useBulkActionsComponent';
import { GridContext } from './data/GridContextManager';

function MultiselectHeader({ containerElement }) {
  const {
    tableId,
    gridApiRef,
    selectedRecordIds,
    selectionMax,
  } = useContext(GridContext);

  const BulkActionsComponent = useBulkActionsComponent(tableId);

  const handleDeselectAll = () => {
    if (!gridApiRef.current) return;

    gridApiRef.current.deselectAll();
  };

  if (!containerElement || selectedRecordIds.length === 0) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={`tw-ml-16 tw-mr-8 tw-relative tw-flex tw-h-full tw-items-center tw-justify-between tw-text-sm tw-text-white tw-font-semibold ${selectedRecordIds.length > 0 ? 'tw-opacity-100 tw-z-50' : 'tw-opacity-0 tw-z-0'} tw-transition-opacity tw-duration-500`}
    >
      {BulkActionsComponent && (
        <div className="tw-flex tw-items-center">
          <BulkActionsComponent
            recordIds={selectedRecordIds}
            gridApi={gridApiRef.current}
            enabled={selectedRecordIds.length <= selectionMax}
          />
        </div>
      )}
      {selectedRecordIds.length <= selectionMax && (
        <span className="tw-ml-auto tw-mr-5 tw-italic">
          Select up to {selectionMax}
        </span>
      )}
      {selectedRecordIds.length > selectionMax && (
        <span className="tw-ml-auto tw-mr-5">
          <i className="fa fa-exclamation-triangle tw-text-warning tw-mr-2" />
          <span className="tw-italic">
            Max selection is {selectionMax}
          </span>
        </span>
      )}

      <button
        type="button"
        className="im-clear tw-p-0 tw-bg-transparent tw-border-0 tw-text-white tw-text-lg tw-cursor-pointer"
        aria-label="Deselect All"
        onClick={handleDeselectAll}
      >
        <i className="fas fa-times" />
      </button>
    </div>,
    containerElement,
  );
}

export default React.memo(MultiselectHeader);

MultiselectHeader.propTypes = {
  containerElement: PropTypes.instanceOf(Element),
};

MultiselectHeader.defaultProps = {
  containerElement: null,
};

// Reports listed in this array will render within LegacyViewer powered by DataTables
const legacyReports = [
];

const showInLegacyViewer = (reportName) => {
  const betaFeaturesEnabled = document.querySelector('meta[name="enable-beta-features"]').getAttribute('content') === 'yes';

  return !betaFeaturesEnabled || legacyReports.includes(reportName);
};

export { showInLegacyViewer, legacyReports };

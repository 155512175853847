import React, {
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '../ErrorMessage';
import MultiselectHeader from './MultiselectHeader';
import ClientSideGrid from './ClientSideGrid';
import ServerSideGrid from './ServerSideGrid';
import useAgColumnVisibility from './hooks/useAgColumnVisibility';
import { GridContext } from './data/GridContextManager';

function IndexViewerBody({ squareBorder }) {
  const {
    ajax,
    columnArray,
    columnDefs,
    currency,
    defaultColDef,
    displayLength,
    errorMessage,
    expectedRecords,
    gridApiRef,
    gridStyle,
    handleBodyScroll,
    handleColumnMoved,
    handleGridReady,
    handleRecordIdsChanged,
    handleRecordsRetrieved,
    handleRowClicked,
    handleSelectionChanged,
    handleSortChanged,
    haveError,
    infiniteScroll,
    isRowSelectable,
    masterDetail,
    quickFilterText,
    rowBuffer,
    serverSide,
    setErrorMessage,
    setHaveError,
    tableId,
  } = useContext(GridContext);
  const tableContainerRef = useRef();
  const headerRowRef = useRef();
  const parsedCurrency = useMemo(() => JSON.parse(currency), [currency]);
  const parsedColumnArray = useMemo(() => (columnArray ? JSON.parse(columnArray) : []), [columnArray]);
  const setColumnVisibility = useAgColumnVisibility(columnDefs, gridApiRef);

  // Effect that updates column visibility when the window resizes
  useEffect(() => {
    const handleResize = () => {
      setColumnVisibility();
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [columnDefs, setColumnVisibility]);

  // Effect to show the UI if there are no records
  useEffect(() => {
    if (expectedRecords === 0) {
      // Allow the "no records" display to show
      if (tableContainerRef.current) {
        tableContainerRef.current.classList.add('tw-transition-opacity', 'tw-duration-500', 'tw-opacity-100');
      }
    }
  }, [expectedRecords]);

  // Fade in the grid when data is ready and scroll to the last viewed row
  const handleFirstDataRendered = useCallback((params) => {
    // Hide the footer for a better user scrolling experience
    const footerElement = document.querySelector('footer');
    if (footerElement) {
      footerElement.classList.add('tw-hidden');
    }
    const topRowIndex = JSON.parse(localStorage.getItem(`TableIndex_${tableId}`));
    if (topRowIndex) {
      params.api.ensureIndexVisible(topRowIndex, 'top');
    }
  }, [tableId]);

  const onGridReady = useCallback((params) => {
    if (tableContainerRef.current) {
      tableContainerRef.current.classList.add('tw-transition-opacity', 'tw-duration-500', 'tw-delay-200', 'tw-opacity-100');
      headerRowRef.current = tableContainerRef.current.querySelector('.ag-header-row');
    }
    handleGridReady(params);
  }, [handleGridReady]);

  return (
    <>
      {!haveError && (
        <div
          ref={tableContainerRef}
          className="table-float tw--mt-px tw-opacity-0 tw-h-full tw-px-0"
        >
          <div className="tw-w-100 tw-h-full tw-relative">
            <MultiselectHeader containerElement={headerRowRef.current} />
            {!serverSide && (
              <ClientSideGrid
                ajax={ajax}
                currency={parsedCurrency}
                defaultColDef={defaultColDef}
                displayLength={displayLength}
                columnDefs={columnDefs}
                gridStyle={gridStyle}
                quickFilterText={quickFilterText}
                tallRows
                rowBuffer={rowBuffer}
                setHaveError={setHaveError}
                setErrorMessage={setErrorMessage}
                onBodyScroll={handleBodyScroll}
                onColumnMoved={handleColumnMoved}
                onFirstDataRendered={handleFirstDataRendered}
                onGridReady={onGridReady}
                onRowClicked={handleRowClicked}
                onSortChanged={handleSortChanged}
                onRecordIdsChanged={handleRecordIdsChanged}
                onSelectionChanged={handleSelectionChanged}
                isRowSelectable={isRowSelectable}
                infiniteScroll={infiniteScroll}
                squareBorder={squareBorder}
                masterDetail={masterDetail}
              />
            )}
            {serverSide && (
              <ServerSideGrid
                ajax={ajax}
                columnArray={parsedColumnArray}
                defaultColDef={defaultColDef}
                columnDefs={columnDefs}
                gridStyle={gridStyle}
                displayLength={displayLength}
                quickFilterText={quickFilterText}
                tallRows
                rowBuffer={rowBuffer}
                setHaveError={setHaveError}
                setErrorMessage={setErrorMessage}
                expectedRecords={expectedRecords}
                onBodyScroll={handleBodyScroll}
                onColumnMoved={handleColumnMoved}
                onFirstDataRendered={handleFirstDataRendered}
                onGridReady={onGridReady}
                onRowClicked={handleRowClicked}
                onSortChanged={handleSortChanged}
                onRecordsRetrieved={handleRecordsRetrieved}
                onSelectionChanged={handleSelectionChanged}
                isRowSelectable={isRowSelectable}
                allowGetRowId
                infiniteScroll={infiniteScroll}
                squareBorder={squareBorder}
                masterDetail={masterDetail}
              />
            )}
          </div>
        </div>
      )}
      {haveError && (
        <ErrorMessage details={errorMessage} />
      )}
    </>
  );
}

IndexViewerBody.propTypes = {
  squareBorder: PropTypes.bool,
};

IndexViewerBody.defaultProps = {
  squareBorder: true,
};

export default React.memo(IndexViewerBody);

import React, {
  createContext,
  useContext,
  useMemo,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { SharedContext } from '../../AgGrid/data/SharedContextManager';
import useSavedColumnPositions from '../../AgGrid/hooks/useSavedColumnPositions';
import agColumnDefAdapter from '../../AgGrid/data/agColumnDefAdapter';

const ViewerContext = createContext();

function ViewerContextProvider({
  children,
  reportId,
  title,
  onRecordsRetrieved,
  onRecordIdsChanged,
}) {
  const sharedContext = useContext(SharedContext);
  const [currentHeaderRows, setCurrentHeaderRows] = useState(0);
  const [negativeOffset, setNegativeOffset] = useState(0);

  const {
    columnArray,
    filters,
    infiniteScroll,
    orderArray,
    railsFormToken,
    serverSide,
  } = sharedContext;

  const memoizedAgColumnDefAdapter = useCallback(() => {
    const savedOrder = JSON.parse(localStorage.getItem(`ReportOrder_${title}`));
    return agColumnDefAdapter(columnArray, savedOrder || orderArray, filters, serverSide);
  }, [columnArray, orderArray, filters, serverSide, title]);

  // eslint-disable-next-line no-unused-vars
  const [columnDefs, _setColumnDefs] = useState(memoizedAgColumnDefAdapter);
  const handleColumnMoved = useSavedColumnPositions(columnDefs, 'report_customizations', reportId, railsFormToken);

  // Define basic config for all columns - columnDefs overrides these
  const defaultColDef = useMemo(() => (
    {
      autoHeaderHeight: true,
      autoHeight: true,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
      },
      enableRowGroup: false,
      filter: false,
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      },
      flex: 1,
      loadingCellRenderer: () => '',
      menuTabs: ['filterMenuTab', 'generalMenuTab'],
      minWidth: 120,
      wrapHeaderText: true,
      wrapText: true,
    }
  ), []);

  const gridStyle = useMemo(() => ({
    height: infiniteScroll ? '78vh' : undefined,
    width: '100%',
  }), [infiniteScroll]);

  const handlePaginationCustomized = useCallback((params) => {
    setCurrentHeaderRows(params.currentHeaderRows);
    setNegativeOffset(params.negativeOffset);
  }, []);

  // Perform action when table row clicked
  const handleRowClicked = useCallback((e) => {
    if (!$(e.event.target).hasClass('inner-link') && ($(e.event.target).attr('data-method') !== 'delete')) {
      const rowUrl = e.data?.DT_RowData?.url;
      const rowRemote = e.data?.DT_RowData?.remote;

      // Navigate to the URL based on the remote boolean
      if (rowRemote) {
        window.location.href = rowUrl;
      } else {
        window.open(rowUrl, '_self');
      }
    }
  }, []);

  const providerValue = useMemo(
    () => ({
      ...sharedContext,
      columnDefs,
      currentHeaderRows,
      defaultColDef,
      gridStyle,
      handleColumnMoved,
      handlePaginationCustomized,
      handleRowClicked,
      negativeOffset,
      onRecordsRetrieved,
      onRecordIdsChanged,
      reportId,
      title,
    }),
    [
      sharedContext,
      columnDefs,
      currentHeaderRows,
      defaultColDef,
      gridStyle,
      handleColumnMoved,
      handlePaginationCustomized,
      handleRowClicked,
      negativeOffset,
      onRecordsRetrieved,
      onRecordIdsChanged,
      reportId,
      title,
    ],
  );

  return (
    <ViewerContext.Provider value={providerValue}>
      {children}
    </ViewerContext.Provider>
  );
}

export { ViewerContext, ViewerContextProvider };

ViewerContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  reportId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onRecordsRetrieved: PropTypes.func.isRequired,
  onRecordIdsChanged: PropTypes.func.isRequired,
};

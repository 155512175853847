import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

function EquipmentBulkActions({
  recordIds,
  gridApi,
  enabled,
}) {
  const handleNewWO = () => {
    if (recordIds.length === 0) return;

    const queryString = `equipment_ids=${recordIds.join(',')}`;
    $.get(`/bulk_issue_work_orders/new?${queryString}`, null, null, 'script');
  };

  const handleEdit = () => {
    if (recordIds.length === 0) return;

    $.get('/bulk_edit_equipment/new', { bulk_edit_equipment: { equipment_ids: recordIds } }, null, 'script');
  };

  const handleSignOut = () => {
    if (recordIds.length === 0) return;

    $.get('/bulk_sign_out_equipment/new', { bulk_sign_out_equipment: { equipment_ids: recordIds } }, null, 'script');
  };

  const handleSignIn = () => {
    if (recordIds.length === 0) return;

    $.get('/bulk_sign_in_equipment/new', { bulk_sign_in_equipment: { equipment_ids: recordIds } }, null, 'script');
  };

  return (
    <>
      <button
        type="button"
        className={`btn-white-multiselect${enabled ? '' : '-disabled'} tw-mr-3 tw-py-1`}
        id="bulk-issue-work-orders"
        onClick={handleNewWO}
        disabled={!enabled}
      >
        New WO
      </button>
      <button
        type="button"
        className={`btn-white-multiselect${enabled ? '' : '-disabled'} tw-mr-3 tw-py-1`}
        id="bulk-edit-equipment"
        onClick={handleEdit}
        disabled={!enabled}
      >
        Edit
      </button>
      <button
        type="button"
        className={`btn-white-multiselect${enabled ? '' : '-disabled'} tw-mr-3 tw-py-1`}
        id="bulk-sign-out-equipment"
        onClick={handleSignOut}
        disabled={!enabled}
      >
        Sign Out
      </button>
      <button
        type="button"
        className={`btn-white-multiselect${enabled ? '' : '-disabled'} tw-mr-3 tw-py-1`}
        id="bulk-sign-in-equipment"
        onClick={handleSignIn}
        disabled={!enabled}
      >
        Sign In
      </button>
      <span>
        {`${recordIds.length} item${recordIds.length === 1 ? '' : 's'}`}
      </span>
    </>
  );
}

EquipmentBulkActions.propTypes = {
  recordIds: PropTypes.array.isRequired,
  gridApi: PropTypes.object,
  enabled: PropTypes.bool.isRequired,
};

EquipmentBulkActions.defaultProps = {
  gridApi: null,
};

export default React.memo(EquipmentBulkActions);

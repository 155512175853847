import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import FilterDropdownButton from '../FilterDropdownButton';
import CustomIntervalModal from '../CustomIntervalModal';
import CustomizeButton from './CustomizeButton';
import SearchClear from '../SearchClear';

function FiltersGroup({
  report, isLoading, onChange, onCustomInterval, onSearch, config, railsFormToken, onCustomize
}) {
  const [intervalModalOpen, setIntervalModalOpen] = useState(false);
  const [searchString, setSearchString] = useState('');
  const searchInputRef = useRef(null);
  const [hideSearchClear, setHideSearchClear] = useState(true);

  const resetSearch = () => {
    // Clear the local search string and submit an empty string to the search handler prop
    setSearchString('');
    onSearch('');
  };

  const submitSearch = () => {
    // Submit the current search string to the search handler prop
    onSearch(searchString);
  };

  const handleSearchChange = (e) => {
    if (e.target.value === '') {
      // Reset the search if the browser-native search input is clicked,
      // resulting in a change to empty string
      setHideSearchClear(true)
      resetSearch();
    } else {
      setHideSearchClear(false)
      setSearchString(e.target.value);
    }
  };

  const handleSeachKeyDown = (e) => {
    // Handle special key cases:
    if (e.keyCode === 27) {
      setHideSearchClear(true)
      resetSearch(); // Escape key
    }
    if (e.key === 'Enter') {
      setHideSearchClear(false)
      submitSearch(); // Enter key
    }
  };

  const handleClearClick = () => {
    setHideSearchClear(true)
    resetSearch();
  };

  useEffect(() => {
    // When isLoading changes from true to false, set focus back onto search input
    if (!isLoading && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isLoading]);

  return (
    <div className="tw-mb-3 tw-grid tw-grid-cols-1 xl:tw-grid-cols-2 xl:tw-gap-3">
      <div className="tw-flex tw-mb-3 lg:tw-mb-0 max-sm:tw-overflow-x-auto max-sm:tw-overflow-y-hidden max-sm:tw-no-scrollbar">
        {!isLoading && 'location_options' in report && (
          <FilterDropdownButton
            options={report.location_options}
            currentText={report.location_label}
            currentSubtext={report.location_sublabel}
            onClick={onChange}
            highlight={report.location_non_default}
            id="location-selector-dropdown"
          />
        )}
        {!isLoading && 'interval_options' in report && (
          <>
            <FilterDropdownButton
              options={report.interval_options}
              currentText={report.interval_label}
              onClick={onChange}
              onLaunchCustom={() => setIntervalModalOpen(true)}
              highlight={report.interval_non_default}
              id="interval-selector-dropdown"
            />
            <CustomIntervalModal
              isOpen={intervalModalOpen}
              onApply={onCustomInterval}
              toDateOnly={report.to_date_only}
              fromDate={report.custom_from_date}
              toDate={report.custom_to_date}
              onClose={() => setIntervalModalOpen(false)}
              dateFormat={report.date_format}
            />
          </>
        )}
        {!isLoading && 'status_options' in report && (
          <FilterDropdownButton
            options={report.status_options}
            currentText={report.status_label}
            onClick={onChange}
            highlight={report.status_non_default}
            id="status-selector-dropdown"
          />
        )}
        {!isLoading && 'custom_filter_options' in report && (
          <FilterDropdownButton
            options={report.custom_filter_options}
            currentText={report.custom_filter_label}
            onClick={onChange}
            highlight={report.custom_filter_non_default}
            id="custom-filter-selector-dropdown"
          />
        )}
      </div>
      <div className="tw-w-full tw-flex tw-flex-col xl:tw-flex-row xl:tw-items-center xl:tw-justify-end">
        {report.customizable && (
          <CustomizeButton
            reportId={report.id}
            config={config}
            railsFormToken={railsFormToken}
            onCustomize={onCustomize}
            orgLogoPresent={report.org_logo_present}
            className="tw-mr-2"
            report_title={report.title}
          />
        )}
        <div>
          <input
            type="search"
            ref={searchInputRef}
            value={searchString}
            onChange={handleSearchChange}
            onKeyDown={handleSeachKeyDown}
            disabled={isLoading}
            placeholder="Search within report"
            className="im-clear im-input-md tw-w-full tw-max-w-xs tw-h-10"
            autoFocus
          />
          <SearchClear
            handleClearClick={handleClearClick}
            hideSearchClear={hideSearchClear}
          />
        </div>
      </div>
    </div>
  );
}

FiltersGroup.propTypes = {
  report: PropTypes.object,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onCustomInterval: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  config: PropTypes.string.isRequired,
  railsFormToken: PropTypes.string.isRequired,
  onCustomize: PropTypes.func,
};

FiltersGroup.defaultProps = {
  report: {},
  isLoading: false,
  onCustomize: () => null,
};

export default React.memo(FiltersGroup);

import React from 'react';
import PropTypes from 'prop-types';
import { GridContextProvider } from './data/GridContextManager';
import IndexViewerBody from './IndexViewerBody';

function IndexViewer({
  tableId,
  ajax,
  columnArray,
  columnsFuncName,
  orderArray,
  filters,
  serverSide,
  displayLength,
  expectedRecords,
  currency,
  infiniteScroll,
  masterDetail,
  customizationName,
  railsFormToken,
  selectionMax,
  squareBorder,
}) {
  return (
    <GridContextProvider
      ajax={ajax}
      columnArray={columnArray}
      columnsFuncName={columnsFuncName}
      contextType="indexViewer"
      currency={currency}
      customizationName={customizationName}
      displayLength={displayLength}
      expectedRecords={expectedRecords}
      filters={filters}
      infiniteScroll={infiniteScroll}
      masterDetail={masterDetail}
      orderArray={orderArray}
      railsFormToken={railsFormToken}
      serverSide={serverSide}
      tableId={tableId}
      selectionMax={selectionMax}
    >
      <IndexViewerBody squareBorder={squareBorder} />
    </GridContextProvider>
  );
}

IndexViewer.propTypes = {
  tableId: PropTypes.string.isRequired,
  ajax: PropTypes.string.isRequired,
  columnArray: PropTypes.string,
  columnsFuncName: PropTypes.string,
  orderArray: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    ),
  ),
  filters: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  serverSide: PropTypes.bool,
  displayLength: PropTypes.number,
  expectedRecords: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  infiniteScroll: PropTypes.bool,
  masterDetail: PropTypes.bool,
  customizationName: PropTypes.string,
  railsFormToken: PropTypes.string.isRequired,
  selectionMax: PropTypes.number,
  squareBorder: PropTypes.bool,
};

IndexViewer.defaultProps = {
  columnArray: '',
  columnsFuncName: '',
  orderArray: [],
  filters: '',
  serverSide: false,
  displayLength: 25,
  infiniteScroll: true,
  masterDetail: false,
  customizationName: '',
  selectionMax: 100,
  squareBorder: true,
};

export default React.memo(IndexViewer);

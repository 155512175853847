function currencyToNumber(currencyString, options) {
  if (typeof currencyString !== 'string') return 0.0;

  const {
    symbol,
    decimalMark,
    thousandsSeparator,
    bidi,
  } = options;

  // Strip the currency symbol span
  let numericString = currencyString.replace(/<span[^>]*>.*?<\/span>/g, '').trim();

  // Handle bidi if specified
  if (bidi && currencyString.includes(symbol)) {
    const parts = numericString.split(decimalMark);
    numericString = `${parts[1]}${decimalMark}${parts[0]}`;
  }

  // Remove thousands separators and replace decimal mark
  numericString = numericString
    .replace(new RegExp(`\\${thousandsSeparator}`, 'g'), '')
    .replace(new RegExp(`\\${decimalMark}`), '.');

  // Attempt parse
  const numericValue = parseFloat(numericString);
  if (Number.isNaN(numericValue)) {
    return 0.0;
  }

  return numericValue;
}

function stringToNumber(inputString, options) {
  if (typeof inputString !== 'string') return 0.0;

  const {
    decimalMark,
    thousandsSeparator,
  } = options;

  // Remove thousands separators and replace decimal mark
  const numericString = inputString
    .replace(new RegExp(`\\${thousandsSeparator}`, 'g'), '')
    .replace(new RegExp(`\\${decimalMark}`), '.');

  // Attempt parse
  const numericValue = parseFloat(numericString);
  if (Number.isNaN(numericValue)) {
    return 0.0;
  }

  return numericValue;
}

function numberToCurrency(number, options) {
  const {
    symbol,
    htmlEntity,
    symbolFirst,
    decimalMark,
    thousandsSeparator,
    bidi,
  } = options;
  const decimalString = number.toFixed(2);
  const currencyString = decimalString.replace('.', decimalMark).replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
  // return symbolFirst
  //   ? `<span class="currency-symbol">${bidi ? htmlEntity : symbol}</span>${currencyString}`
  //   : `${currencyString}<span class="currency-symbol">${bidi ? htmlEntity : symbol}</span>`;
  return `<span class="currency-symbol">${bidi ? htmlEntity : symbol}</span>${currencyString}`;
}

function numberToString(number, options) {
  const {
    decimalMark,
    thousandsSeparator,
  } = options;

  if (decimalMark === '') {
    // Format the number without decimals, apply thousands separator
    return number
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
  }

  // Format the number with decimals
  const decimalString = number.toFixed(2);
  return decimalString
    .replace('.', decimalMark)
    .replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
}

export function htmlSum(currencyParams) {
  return (params) => {
    const { values } = params;
    const sum = values.reduce((accumulator, currentValue) => {
      const numericValue = currencyToNumber(currentValue, currencyParams);
      return accumulator + numericValue;
    }, 0.0);
    return numberToCurrency(sum, currencyParams);
  };
}

export function stringSum(options) {
  return (params) => {
    const { values } = params;
    const sum = values.reduce((accumulator, currentValue) => {
      const numericValue = stringToNumber(currentValue, options);
      return accumulator + numericValue;
    }, 0.0);

    if (Number.isInteger(sum)) {
      // Don't include decimal point for integer values
      return numberToString(sum, { ...options, decimalMark: '', thousandsSeparator: options.thousandsSeparator });
    }

    return numberToString(sum, options);
  };
}

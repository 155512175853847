import { useCallback, useRef } from 'react';
import axios from 'axios';
import agServerRequestAdapter from './agServerRequestAdapter';

// Creates the datasource AG Grid expects for server-side pagination
function useServerSideDatasource(
  ajax,
  columnArray,
  columnDefs,
  displayLength,
  groupByColumn,
  enableServerGroupBy,
  search,
  setTotalsRowData,
  setHaveError,
  setErrorMessage,
  onRecordsRetrieved,
  onPaginationCustomized,
) {
  const lastGroupByRef = useRef();
  const drawRef = useRef(1);
  const negativeOffset = useRef(0);

  const datasource = useCallback({
    getRows(sourceParams) {
      sourceParams.api.hideOverlay();
      const params = agServerRequestAdapter({
        request: sourceParams.request,
        length: displayLength,
        columnArray,
        draw: drawRef.current,
        enableServerGroupBy,
        groupByColumn,
        search,
        filterModel: sourceParams.request?.filterModel,
        negativeOffset: negativeOffset.current,
      });

      // Notify parent component(s) of record retrieval
      onRecordsRetrieved(params);

      // Reset the negative offset count when we're on the first page
      if (sourceParams.request.startRow === 0) negativeOffset.current = 0;

      axios.get(ajax, { params })
        .then((response) => {
          const rawData = response.data.data;
          let processedData = [];
          let headerRowCount = 0;
          const availableRecordCount = response.data.recordsFiltered || 0;

          // If grouping enabled, insert a header row at each change in group-by column but not
          // when the groups record continue across a pagination boundary.
          if (groupByColumn && enableServerGroupBy) {
            const firstVisibleColumn = columnDefs.find((e) => e.hide !== true);

            rawData.forEach((item, index) => {
              const currentGroupBy = item[groupByColumn.field];
              if (currentGroupBy !== lastGroupByRef.current) {
                if (!(index === 0 && lastGroupByRef.current === currentGroupBy)) {
                  // Create a new header row
                  const headerRow = {
                    isHeader: true,
                    [firstVisibleColumn.field]: currentGroupBy,
                  };

                  // Add subtotals to the header row if present in data
                  columnDefs.forEach((colDef) => {
                    if (colDef.field.endsWith('_subtotal')) {
                      const baseField = colDef.field.slice(0, -9);
                      headerRow[baseField] = item[colDef.field];
                    }
                  });

                  if (processedData.length < displayLength) processedData.push(headerRow);
                  headerRowCount += 1;
                }
                lastGroupByRef.current = currentGroupBy;
              }
              // Grouping not enabled, so use the data as-is.
              if (processedData.length < displayLength) processedData.push(item);
            });
          } else {
            processedData = rawData;
          }

          // Add the totals row data
          if (rawData.length > 0) {
            const totals = {};
            // All rows have the total from server, so just pick the 1st one.
            const dataRow = rawData[0];
            columnDefs.forEach((colDef) => {
              if (colDef.field.endsWith('_total')) {
                const baseField = colDef.field.slice(0, -6);
                totals[baseField] = dataRow[colDef.field];
              }
            });

            setTimeout(() => {
              if (Object.keys(totals).length > 0) {
                setTotalsRowData([totals]);
              } else {
                setTotalsRowData([]);
              }
            }, 100);
          } else {
            setTimeout(() => {
              setTotalsRowData([]);
            }, 100);
          }

          // Track the negative offset for the next request based on added header row
          negativeOffset.current += headerRowCount;

          // Let the grid know we have a page of data
          onPaginationCustomized({ currentHeaderRows: headerRowCount, negativeOffset: negativeOffset.current });
          sourceParams.success({ rowData: processedData, rowCount: availableRecordCount + negativeOffset.current });
          drawRef.current += 1;

          if (groupByColumn && enableServerGroupBy) {
            const firstVisibleColumn = columnDefs.find((e) => e.hide !== true);
            sourceParams.api.autoSizeColumn(firstVisibleColumn.field);
          }

          // Use the API to display the no rows overlay when there are no records
          if (availableRecordCount <= 0) sourceParams.api.showNoRowsOverlay();
        })
        .catch((error) => {
          setHaveError(true);
          setErrorMessage(`Encountered an error while retrieving records: ${error.message}`);
          // Let the grid know things have gone awry
          sourceParams.fail();
        });
    },
  }, [ajax, columnDefs, displayLength, groupByColumn, enableServerGroupBy, search]);

  return { datasource };
}

export default useServerSideDatasource;

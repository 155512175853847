import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import '../utilities/help_panel.js.coffee';
import { wrap } from 'regenerator-runtime';

function HideableHint(props, ref) {
  const { hints, hintClass, alwaysShow, wrapContainer } = props;
  if (!hints || hints.length === 0) {
    return null;
  }

  const [flyOut, setFlyOut] = useState(false);
  const [currentHintIndex, setCurrentHintIndex] = useState(0);
  const [priorHintIndex, setPriorHintIndex] = useState();
  const currentHint = hints[currentHintIndex];
  const priorHint = hints[priorHintIndex]

  useEffect(() => {
    if (priorHintIndex === undefined) {
      return;
    }

    axios({
        method: 'get',
        url: '/hideable_hints/' + priorHint.id + '/hide',
        headers: {
          'Accept': 'application/json'
        },
        responseType: 'json'
    })
    .then(response => {
      if (response.status === 200) {
        console.log('Hint hidden');
      } else {
        console.log(response);
      }
    })
    .catch(error => {
      console.error('Error hiding hint:', error);
    });
  }, [currentHintIndex]);

  useEffect(() => {
    // Call Utility.HelpPanel.prepare() to attach the click handler
    Utility.HelpPanel.prepare();
  }, []);

  const hideHint = () => {
    setFlyOut(true);
    setTimeout(() => {
      setPriorHintIndex(currentHintIndex);
      setCurrentHintIndex((prevIndex) => prevIndex + 1);
      setFlyOut(false);
    }, 500); // Delay set to match CSS animation duration
  };

  if (!currentHint) {
    return null;
  }

  const mainContent = (
    <div id='hideable-hint' className={`info-box ${hintClass} ${flyOut ? 'animate-fly-out-left' : 'animate-fly-in-right'}`} ref={ref}>
      <div className="info-icon">
        <div className="fa fa-info compact"/>
      </div>
      <div className="info-text">
        {!alwaysShow && (
          <span className="close" data-hint-id={currentHint.id} onClick={() => hideHint()}>
            <i className="fas fa-times"/>
            <br/>
            <span className="close-title">Got it!</span>
          </span>
        )}
        <strong>{currentHint.title}</strong>
        <br/>
        <span dangerouslySetInnerHTML={{ __html: currentHint.body }} />
      </div>
    </div>
  );

  return (
    wrapContainer
      ? <div className="hint-container tw-inline-block">{mainContent}</div>
      : mainContent
  );
};

export default React.forwardRef(HideableHint);

HideableHint.propTypes = {
  hints: PropTypes.arrayOf(PropTypes.object).isRequired,
  alwaysShow: PropTypes.bool,
  hintClass: PropTypes.string,
  wrapContainer: PropTypes.bool,
};

HideableHint.defaultProps = {
  alwaysShow: false,
  wrapContainer: false,
};
